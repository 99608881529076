
import React from 'react';
import ReactDOM from 'react-dom';

import Main from './app/containers/Main';

ReactDOM.render(
	<Main />,
	document.getElementById('app')
);
