import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';



class Main extends React.Component {

	static propTypes = {};

	static defaultProps = {};

	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {

	}


	render() {
		return (
			<div className="text-center p-5">
			<h1>License Plate Games</h1>
				<a className="d-block" href="https://play.google.com/store/apps/details?id=com.licenseplategames.android"><img src={require('../img/google-play-badge.png')} /></a>

			<footer>
				<small className="text-muted">Android, Google Play, and the Google Play logo are trademarks of Google Inc.</small>
			</footer>
			</div>

		)

	}

}

const mapStateToProps = (state) => {

	return {

	}
};

export default Main;
